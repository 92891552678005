<template>
  <el-scrollbar class="sidebar-menu" wrap-class="scrollbar-wrapper">
    <el-menu :default-active="activeIndex" :router="true" menu-trigger="click" background-color="#eaecef"
      active-text-color="#303133">
      <el-menu-item-group>
        <template slot="title">{{ $t("route.service") }}</template>
        <div v-for="t in routes" :key="t.index">
          <el-submenu v-if="t.children && t.children.length > 0" :index="t.index">
            <template slot="title">
              <i :class="t.icon"></i>
              <span>{{ t.title }}</span>
            </template>
            <el-menu-item v-for="ct in t.children" :index="ct.index" :key="ct.index">
              <template slot="title">
                <i :class="ct.icon"></i>
                <span>{{ ct.title }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-menu-item v-else :index="t.index" :disabled="t.disable">
            <i :class="t.icon"></i>
            <span slot="title">{{ t.title }}</span>
          </el-menu-item>
        </div>
      </el-menu-item-group>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { MenuList } from "@/router/menu";
export default {
  data() {
    return {
      activeIndex: null,
      routes: [],
    };
  },
  created() {
    let routes = [
      {
        index: "/services/data",
        key: "data-center",
        children: [
          {
            index: "/services/data-center/weekly-board",
            key: "weekly-board",
          },
          {
            index: "/services/data-center/sales",
            key: "sales",
          },
          {
            index: "/services/data-center/stock",
            key: "stock",
          },
          {
            index: "/services/data-center/image-data",
            key: "image-data",
          },
        ],
      },
      {
        index: "/services/visual",
        key: "visual",
        children: [
          {
            index: "/services/visual/street-fashion",
            key: "street-fashion",
          },
          {
            index: "/services/visual/street-trend",
            key: "street-trend",
          },
          {
            index: "/services/visual/window-fashion",
            key: "window-fashion",
          },
          {
            index: "/services/visual/window-trend",
            key: "window-trend",
          },
          {
            index: "/services/visual/global-trend",
            key: "global-trend",
          },
        ],
      },
      {
        index: "/services/analysis",
        key: "analysis",
        children: [
          {
            index: "/services/analysis/sales-item",
            key: "sales-item",
          },
          {
            index: "/services/analysis/sales-trend",
            key: "sales-trend",
          },
          {
            index: "/services/analysis/weekly-sales",
            key: "weekly-sales",
          },
          {
            index: "/services/analysis/style-search",
            key: "style-search",
          },
          {
            index: "/services/analysis/new-product",
            key: "new-product",
          },
          {
            index: "/services/analysis/rising-product",
            key: "rising-product",
          },
          {
            index: "/services/analysis/specific-item",
            key: "specific-item",
          },
          {
            index: "/services/analysis/product-detail",
            key: "product-detail",
          },
          {
            index: "/services/analysis/channel",
            key: "channel",
          },
          {
            index: "/services/analysis/place",
            key: "place",
          },
          {
            index: "/services/analysis/concept",
            key: "concept",
          },
          {
            index: "/services/analysis/best10",
            key: "best20-report",
          },
          {
            index: "/services/analysis/best20-images",
            key: "best20-images",
          },
          {
            index: "/services/analysis/tps",
            key: "tps",
          },
          {
            index: "/services/analysis/matrix",
            key: "matrix",
          },
        ],
      },
      {
        index: "/services/global-trends",
        key: "global-trends",
        children: [
          {
            index: "/services/global-trends/item-dashboard",
            key: "item-dashboard",
          },
          {
            index: "/services/global-trends/trend-dashboard",
            key: "trend-dashboard",
          },
          {
            index: "/services/global-trends/item-compare",
            key: "item-compare",
          },
          {
            index: "/services/global-trends/trend-compare",
            key: "trend-compare",
          },
          {
            index: "/services/global-trends/item-search",
            key: "item-search",
          },
          {
            index: "/services/global-trends/trend-search",
            key: "trend-search",
          },
          {
            index: "/services/global-trends/item-prediction",
            key: "item-prediction",
          },
          {
            index: "/services/global-trends/trend-prediction",
            key: "trend-prediction",
          },
        ],
      },
      {
        index: "/services/report",
        key: "report",
        children: [
          {
            index: "/services/report/image-trend-map",
            key: "image-trend-map",
          },
          {
            index: "/services/report/price-report",
            key: "price-report",
          },
          {
            index: "/services/report/item-report",
            key: "item-report",
          },
          {
            index: "/services/report/color-report",
            key: "color-report",
          },
          {
            index: "/services/report/textile-report",
            key: "textile-report",
          },
          {
            index: "/services/report/consulting-report",
            key: "consulting-report",
          },
          {
            index: "/services/report/global-trend-report",
            key: "global-trend-report",
          },
        ],
      },
      {
        index: "/services/nlp",
        key: "nlp",
        children: [
          {
            index: "/services/nlp/popular-elements",
            key: "popular-elements",
          },
          {
            index: "/services/nlp/product-name",
            key: "product-name",
          },
          {
            index: "/services/nlp/search-word",
            key: "search-word",
          },
          {
            index: "/services/nlp/product-reviews",
            key: "product-reviews",
          },
          {
            index: "/services/nlp/sns",
            key: "sns",
          },
        ],
      },
      {
        index: "/services/gpt",
        key: "gpt",
        children: [
          {
            index: "/services/gpt/creator",
            key: "creator",
          },
          {
            index: "/services/gpt/lookbook",
            key: "lookbook",
          },
        ],
      },
      {
        index: "/services/gpt-designer",
        key: "gpt-designer",
        children: [
          {
            index: "/services/gpt-designer/designer",
            key: "designer",
          },
          {
            index: "/services/gpt-designer/sketches",
            key: "sketches",
          },
        ],
      },
      {
        index: "/services/gpt-morph",
        key: "gpt-morph",
        children: [
          {
            index: "/services/gpt-morph/morph",
            key: "morph",
          },
          {
            index: "/services/gpt-morph/library",
            key: "library",
          },
        ],
      },
      {
        index: "/services/gpt-concept",
        key: "gpt-concepter",
        children: [
          {
            index: "/services/gpt-concept/concept",
            key: "concepter",
          },
          {
            index: "/services/gpt-concept/sketches",
            key: "concepter-sketches",
          },
        ],
      },
      {
        index: "/services/gpt-drawing",
        key: "gpt-drawing",
        children: [
          {
            index: "/services/gpt-drawing/drawing",
            key: "drawing",
          },
          {
            index: "/services/gpt-drawing/book",
            key: "drawing-book",
          },
        ],
      },
      {
        index: "/services/gpt-ad",
        key: "gpt-ad",
        children: [
          {
            index: "/services/gpt-ad/ad",
            key: "ad",
          },
          {
            index: "/services/gpt-ad/library",
            key: "ad-library",
          },
        ],
      },
      {
        index: "/services/tagger",
        key: "tagger",
      },
      {
        index: "/services/aeye",
        key: "aeye",
        disable: true,
      },
    ];
    for (let i = 0; i < routes.length; i++) {
      let valid = this.filterRoute(routes[i]);
      if (valid) {
        this.routes.push(valid);
      }
    }
    switch (this.$route.name) {
      case "Tagger":
      case "Tagger Detail":
      case "Tagger New":
        this.activeIndex = "/services/tagger";
        break;
      case "Weekly Board":
        this.activeIndex = `/services/data-center/weekly-board`;
        break;
      case "Street Fashion":
        this.activeIndex = `/services/visual/street-fashion`;
        break;
      case "Window Fashion":
        this.activeIndex = `/services/visual/window-fashion`;
        break;
      case "Global Trend":
        this.activeIndex = `/services/visual/global-trend`;
        break;
      case "Analysis Best10":
      case "Analysis Best10 Report Generate":
        this.activeIndex = `/services/analysis/best10`;
        break;
      case "Analysis TPS":
      case "Analysis TPS Report Generate":
        this.activeIndex = `/services/analysis/tps`;
        break;
      case "Analysis Matrix":
      case "Analysis Matrix Report Generate":
        this.activeIndex = `/services/analysis/matrix`;
        break;
      case "Analysis Best20 Image":
        this.activeIndex = `/services/analysis/best20-images`;
        break;
      case "Analysis Data Sales":
        this.activeIndex = `/services/data-center/sales`;
        break;
      case "Analysis Data Stock":
        this.activeIndex = `/services/data-center/stock`;
        break;
      case "Sales Item List":
        this.activeIndex = "/services/analysis/sales-item";
        break;
      case "Weekly Sales":
        this.activeIndex = "/services/analysis/weekly-sales";
        break;
      case "Style Search":
        this.activeIndex = "/services/analysis/style-search";
        break;
      case "Sales Trend":
        this.activeIndex = "/services/analysis/sales-trend";
        break;
      case "New Product":
        this.activeIndex = "/services/analysis/new-product";
        break;
      case "Rising Product":
        this.activeIndex = "/services/analysis/rising-product";
        break;
      case "Product Detail":
        this.activeIndex = "/services/analysis/product-detail";
        break;
      case "Channel":
        this.activeIndex = "/services/analysis/channel";
        break;
      case "Place":
        this.activeIndex = "/services/analysis/place";
        break;
      case "Concept":
        this.activeIndex = "/services/analysis/concept";
        break;
      case "Specific Item":
        this.activeIndex = "/services/analysis/specific-item";
        break;
      case "Image Data":
        this.activeIndex = `/services/data-center/image-data`;
        break;
      case "Item Dashboard":
        this.activeIndex = `/services/global-trends/item-dashboard`;
        break;
      case "Trend Dashboard":
        this.activeIndex = `/services/global-trends/trend-dashboard`;
        break;
      case "Item Compare":
        this.activeIndex = `/services/global-trends/item-compare`;
        break;
      case "Trend Compare":
        this.activeIndex = `/services/global-trends/trend-compare`;
        break;
      case "Item Search":
        this.activeIndex = `/services/global-trends/item-search`;
        break;
      case "Trend Search":
        this.activeIndex = `/services/global-trends/trend-search`;
        break;
      case "Item Prediction":
        this.activeIndex = `/services/global-trends/item-prediction`;
        break;
      case "Trend Prediction":
        this.activeIndex = `/services/global-trends/trend-prediction`;
        break;
      case "Image Trend Map":
        this.activeIndex = `/services/report/image-trend-map`;
        break;
      case "Price Report":
        this.activeIndex = `/services/report/price-report`;
        break;
      case "Item Report":
        this.activeIndex = `/services/report/item-report`;
        break;
      case "Color Report":
        this.activeIndex = `/services/report/color-report`;
        break;
      case "Textile Report":
        this.activeIndex = `/services/report/textile-report`;
        break;
      case "Consulting Report":
        this.activeIndex = `/services/report/consulting-report`;
        break;
      case "Street Trend":
        this.activeIndex = `/services/visual/street-trend`;
        break;
      case "Global Trend Report":
        this.activeIndex = `/services/report/global-trend-report`;
        break;
      case "Window Trend":
        this.activeIndex = `/services/visual/window-trend`;
        break;
      case "Search Word":
        this.activeIndex = `/services/nlp/search-word`;
        break;
      case "Product Name":
        this.activeIndex = `/services/nlp/product-name`;
        break;
      case "Popular Elements":
        this.activeIndex = `/services/nlp/popular-elements`;
        break;
      case "Product Reviews":
        this.activeIndex = `/services/nlp/product-reviews`;
        break;
      case "SNS":
        this.activeIndex = `/services/nlp/sns`;
        break;
      case "Creator":
      case "GPT New":
      case "GPT Detail":
        this.activeIndex = `/services/gpt/creator`;
        break;
      case "My LOOKBOOK":
        this.activeIndex = `/services/gpt/lookbook`;
        break;
      case "AI Design":
      case "AI Design New":
      case "AI Design Detail":
        this.activeIndex = `/services/gpt-designer/designer`;
        break;
      case "Fashion Sketches":
        this.activeIndex = `/services/gpt-designer/sketches`;
        break;
      case "Model Replace":
      case "Model Replace New":
      case "Model Replace Detail":
        this.activeIndex = `/services/gpt-morph/morph`;
        break;
      case "Model Library":
        this.activeIndex = `/services/gpt-morph/library`;
        break;
      case "AI Concepter":
      case "AI Concepter New":
      case "AI Concepter Detail":
        this.activeIndex = `/services/gpt-concept/concept`;
        break;
      case "Concept Sketches":
        this.activeIndex = `/services/gpt-concept/sketches`;
        break;
      case "AI Drawing":
      case "AI Drawing New":
      case "AI Drawing Detail":
        this.activeIndex = `/services/gpt-drawing/drawing`;
        break;
      case "Drawing Book":
        this.activeIndex = `/services/gpt-drawing/book`;
        break;
      case "AD":
      case "AD New":
      case "AD Detail":
        this.activeIndex = `/services/gpt-ad/ad`;
        break;
      case "AD Library":
        this.activeIndex = `/services/gpt-ad/library`;
        break;
      default:
        this.activeIndex = null;
        break;
    }
  },
  methods: {
    findKey(menuList, key) {
      for (let i = 0; i < menuList.length; i++) {
        if (menuList[i].key === key) {
          return true;
        }
        if (
          this.findKey(menuList[i].children ? menuList[i].children : [], key)
        ) {
          return true;
        }
      }
      return false;
    },
    displayMenu(key) {
      let menuList =
        this.$store.getters.my &&
          this.$store.getters.my.role &&
          this.$store.getters.my.role.menu
          ? this.$store.getters.my.role.menu
          : [];
      return this.findKey(menuList, key);
    },
    filterRoute(route) {
      let children = [];
      if (route.children) {
        for (let i = 0; i < route.children.length; i++) {
          let valid = this.filterRoute(route.children[i]);
          if (valid) {
            children.push(valid);
          }
        }
        route.children = children;
      }
      if (this.displayMenu(route.key)) {
        let m = MenuList[route.key];
        if (!m || !m.i18n) {
          return;
        }
        route.title = this.$t(m.i18n);
        route.icon = m.icon;
        return route;
      }
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters.my && this.$store.getters.my.adminType
        ? this.$store.getters.my.adminType.length > 0
        : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-menu {
  /deep/.scrollbar-wrapper {
    overflow-x: hidden !important;
  }

  .el-menu-item {
    height: 44px;
    line-height: 44px;
  }

  /deep/.el-submenu__title {
    height: 44px;
    line-height: 44px;
  }

  .is-active {
    background-color: #bbbdbf !important;
    box-shadow: inset 3px 0 0 0 #3385ff;
  }
}
</style>